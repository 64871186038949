import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ExpandablePanel = makeShortcode("ExpandablePanel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "benefits"
    }}>{`Benefits`}</h1>
    <p><a parentName="p" {...{
        "href": "#concepts"
      }}>{`Concepts`}</a></p>
    <p><a parentName="p" {...{
        "href": "#examples-and-howtos"
      }}>{`Howtos`}</a></p>
    <h2 {...{
      "id": "concepts"
    }}>{`Concepts`}</h2>
    <p>{`In this section:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#loyalty-programs"
        }}>{`What is a loyalty program in Entur`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#time-limited-loyalty-programs"
        }}>{`What are the types of loyalty programs and what can we do with them`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#loyalty-program-versions"
        }}>{`Loyalty program versioning, why and how`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#contracts"
        }}>{`Contracts and their customers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#response-codes"
        }}>{`Response codes in the benefits API`}</a></li>
    </ul>
    <h3 {...{
      "id": "loyalty-programs"
    }}>{`Loyalty programs`}</h3>
    <p>{`A loyalty program within the Entur systems is an extension to a product.
Thus, a loyalty program will always have a unique product attached.
The product may give fare reductions, but this is managed elsewhere in the Entur systems.
In these cases, this document will use the term "discount rights", as in "the customer has discount
rights from the XX product" `}</p>
    <p>{`Loyalty programs are divided into three main types:`}</p>
    <ul>
      <li parentName="ul">{`time-limited`}</li>
      <li parentName="ul">{`coupon-limited`}</li>
      <li parentName="ul">{`points-limited`}</li>
    </ul>
    <p>{`All types have a first date of validity and an expiration date (even if it may be null).`}</p>
    <p>{`This is an overview of the data structure:
`}<img alt="class diagram" src={require("./classes.png")} /></p>
    <h4 {...{
      "id": "time-limited-loyalty-programs"
    }}>{`Time-limited loyalty programs`}</h4>
    <p>{`Identifier: "TIMED"
An example of a time-limited loyalty program is "a customer card". For this type of loyalty program
Entur supports registering any discounts the contract has been used to garner. It is not possible to
register points-transactions or coupons on contracts for a time-limited loyalty program.`}</p>
    <h4 {...{
      "id": "coupon-limited-loyalty-programs"
    }}>{`Coupon-limited loyalty programs`}</h4>
    <p>{`Identifier: "COUPONS"
An example of a coupons-limited loyalty program is "a 10-coupon daily reduction", where a customer
is allowed 10 separate days of discount rights. In this case, the coupons-limited loyalty program
is configured to create a contract for a time-limited loyalty program for a given period (here, 24h)
from first purchase. On contracts for this type of loyalty program, neither points-transactions or
discounts may be registered. However, if configured, usage will be registered on the created
coupons-contracts. `}</p>
    <h4 {...{
      "id": "points-limited-loyalty-programs"
    }}>{`Points-limited loyalty programs`}</h4>
    <p>{`Identifier: "POINTS"
This type of loyalty program is currently used for gift cards. Contracts for a Points-limited
loyalty program can contain a list of points-transactions. The type of the points is specified
on the initial deposit, and can not be mixed. It is not possible to register discounts or coupons on
a contract for a points-based loyalty program.   `}</p>
    <h4 {...{
      "id": "loyalty-program-versions"
    }}>{`Loyalty program versions`}</h4>
    <p>{`Loyalty programs are versioned by date. For each version, these fields can be changed:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Product version. If a new product version is available in the products db, you can create a new
loyalty program version to match.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Version start and end times. A Loyalty Program is valid if there exists a version with startDate
before now and endDate after now. If multiple versions have overlapping time periods, the one
with the highest versionNumber is considered the CURRENT version.
If the startDate is in the future, it is considered to be in DRAFT status. Creating new versions
will overwrite the current DRAFT version.
If the endDate is in the past, the version is considered DEPRECATED and no new contracts can be
created for this version. If all versions are DEPRECATED, no new contracts can be created and no
existing contracts will be accessible.
In short:`}</p>
        <p parentName="li">{`  `}<inlineCode parentName="p">{`DRAFT`}</inlineCode>{` means it is possible to save the loyalty program for further edits or another publish date, but it is not possible to create contracts on the program.
`}<inlineCode parentName="p">{`CURRENT`}</inlineCode>{` mean it is possible to create contracts.
`}<inlineCode parentName="p">{`DEPRECATED`}</inlineCode>{` means that it is no longer possible to create contracts`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Descriptions. If the descriptions are not altered when a new version is created, they are copied
to the new version.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For COUPONS based loyalty programs, you can adjust the couponConfig by making a new version. `}</p>
      </li>
    </ul>
    <p>{`Call to create a new version:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.staging.entur.io/customers/v2/benefits/loyaltyprograms/{loyaltyProgramId}/versions
{
  "descriptions": [
    {
      "languageCode": "NOB",
      "description": "Reis Kundekort gir deg 20% rabatt på alle kjøp"
    }
  ],
  "startDate": "2007-12-03T10:15:30+01:00",
  "endDate": "2007-12-03T10:15:30+01:00",
  "usageValidityPeriod": "P3DT12H30M5S",
  "defaultCouponsLimit": 10,
  "productVersion": "ENT:Version:V1"
}
`}</code></pre>
    <p>{`Response:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "id": 2324,
  "organisationId": 20,
  "internalDescription": "Customer card with 15 % discount",
  "productId": "string",
  "productVersion": "string",
  "startDate": "2019-12-20T09:40:18.213Z",
  "endDate": "2019-12-20T09:40:18.213Z",
  "usageValidityPeriod": "P3DT12H30M5S",
  "defaultCouponsLimit": 10,
  "versionNumber": 2,
  "status": "DRAFT",
  "descriptions": [
    {
      "languageCode": "NOB",
      "description": "Reis Kundekort gir deg 20% rabatt på alle kjøp",
      "createdAt": "2007-12-03T10:15:30+01:00",
      "createdBy": "123e4567-e89b-12d3-a456-426655440000",
      "lastChangedAt": "2007-12-03T10:15:30+01:00",
      "lastChangedBy": "123e4567-e89b-12d3-a456-426655440000"
    }
  ]
}
`}</code></pre>
    <h5 {...{
      "id": "usage-validity"
    }}>{`Usage validity`}</h5>
    <p>{`A loyalty program may define a usageValidityPeriod, governing how long any attached contracts are valid.
To avoid ambiguity, only Day and Time units are allowed.`}</p>
    <h4 {...{
      "id": "contracts"
    }}>{`Contracts`}</h4>
    <p>{`A Contract is the connection from one or more customers to a Loyalty Program. A Contract can be
considered an instance of a loyalty program. So, for instance, if the loyalty program is points-based
and relates to the fictional product ENT:PointsBasedProduct:GiftCard, each Contract can be considered
a single gift card. Another example, if we have the product ENT:EntitlementProduct:levelA1 (personnel
ticket, silver) and there exists a loyalty program with this product, each Contract can be considered
an entitlement for one specific employee or family member.`}</p>
    <p>{`There are some limitations imposed on the types of thing you can register on a Contract based on the
type of the loyalty program it belongs to. Read more about this `}<a parentName="p" {...{
        "href": "#time-limited-loyalty-programs"
      }}>{`above`}</a>{`.
In particular, only POINTS-based Contracts may have transactions, only TIMED Contracts may have OrderLineEvents
and only COUPONS-based Contracts may have child Contracts - each representing a used coupon.`}</p>
    <p>{`Contracts may have Consumers. A Consumer is a Customer, identified by customerNumber and organisationId.
The endpoint also accepts customerRef which may be useful for identifying external customers down
stream in the sales process. Consumers have two flags:`}</p>
    <ul>
      <li parentName="ul">{`isContractHolder: Whether this consumer is the current contract holder.
Only one contract holder can exist for a given contract.
Contract holders must be from the same organisation that owns the Contract.
It is not possible to add Consumers with isContractHolder=false unless there exists a Consumer
where isContractHolder=true.`}</li>
      <li parentName="ul">{`isBlocked: If a Consumer is marked as blocked, it will not show up as a valid contract for this
customer when searching. At the same time, since there exists a Consumer for that customer, they
can not create a new Consumer. This effectively blocks this customer from using this contract.`}</li>
    </ul>
    <p>{`A Contract may be created with a set of policies. Policies are values that need to be supplied with
correct value to add a consumer to the Contract. For instance, if a Contract is created with a policy
that requires any consumers to be called "Anders", only customers supplying this information will
be able to consume the contract.`}</p>
    <p>{`First, we create the Contract:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST https://api.staging.entur.io/customers/v2/benefits/loyaltyprograms/6/contracts -H"Authorization: Bearer $PARTNER_TOKEN" -H'Content-Type: application/json' -d'
{
  "consumableFrom": "2019-11-01T10:15:30+01:00",
  "policies": [{"key":"name","value":"Anders"}],
  "expirationDate": "2040-11-01T10:15:30+01:00"
}
'
`}</code></pre>
    <p>{`Response:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "uuid": "f1d419bc-e88e-43c9-bf16-6943921a9580",
...
  "policies": [
    {
      "id": 969,
      "key": "name"
    }
  ],
  "contractConsumers": null,
...
}
`}</code></pre>
    <p>{`Attempt to consume with wrong name:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST "https://api.staging.entur.io/customers/v2/benefits/contracts/f1d419bc-e88e-43c9-bf16-6943921a9580/contract-consumer" -H"Authorization: Bearer $PARTNER_TOKEN" -H'Content-Type: application/json' -d'
{
  "customerNumber": 1234567,
  "customerRef": "1234567",
  "customerOrganisationId": 1,
  "isContractHolder": true,
  "policyValidationProperties": {
      "name": "Benny"
  }
}'
`}</code></pre>
    <p>{`Response:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "timestamp": "2020-03-13T09:29:04Z",
  "status": 401,
  "error": "Unauthorized",
  "path": "/benefits/contracts/f1d419bc-e88e-43c9-bf16-6943921a9580/contract-consumer",
  "message": "Add contract consumer for contract f1d419bc-e88e-43c9-bf16-6943921a9580 failed. The contract policies didn't match the policies provided in the request.",
  "correlationId": "9d54fd0c-8207-43f7-a4b9-7fb5aa333b46"
}
`}</code></pre>
    <p>{`Attempt to consume with correct name:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST "https://api.staging.entur.io/customers/v2/benefits/contracts/f1d419bc-e88e-43c9-bf16-6943921a9580/contract-consumer" -H"Authorization: Bearer $PARTNER_TOKEN" -H'Content-Type: application/json' -d'
{
  "customerNumber": 1234567,
  "customerRef": "1234567",
  "customerOrganisationId": 1,
  "isContractHolder": true,
  "policyValidationProperties": {
      "name": "Anders"
  }
}'
`}</code></pre>
    <p>{`Response:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "customerOrganisationId": 1,
  "customerNumber": 1234567,
  "customerRef": "1234567",
  "isBlocked": false,
  "isContractHolder": true,
  "createdAt": "2020-03-13T09:31:24Z",
  "createdBy": "6BiN61j5CQBeU7w4fa72x6pIqdmhcEO6",
  "lastChangedAt": "2020-03-13T09:31:24Z",
  "lastChangedBy": "6BiN61j5CQBeU7w4fa72x6pIqdmhcEO6"
}
`}</code></pre>
    <h4 {...{
      "id": "response-codes"
    }}>{`Response codes`}</h4>
    <p>{`Calling contracts/validate-consumptions allows the caller to check if the given contracts are available for the given customers on the given day.
The relevant response codes are:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`message`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3101`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ok. All are valid.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3102`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Some of the passed contracts are unknown.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3103`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Some of the passed contracts are unavailable on the date of travel.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3104`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Some of the passed contracts are not available for the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3105`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Some of the passed contracts are blocked for the customer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3106`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Some of the passed contracts are out of coupons and no non-coupon contract was found on this date.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "examples-and-howtos"
    }}>{`Examples and Howtos`}</h2>
    <p>{`In this section:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-to-create-a-loyalty-program"
        }}>{`How to create a new loyalty program`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-to-create-a-contract"
        }}>{`How to create a contract`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-to-connect-a-profile-to-a-contract"
        }}>{`How to connect to the new contract`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-to-connect-a-profile-to-a-personnel-ticket-right"
        }}>{`How to connect a customer to a personnel ticket contract`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#using-contracts-for-entitlement-products-in-the-sales-process"
        }}>{`How to use contracts in the sales process`}</a></li>
    </ul>
    <h3 {...{
      "id": "how-to-create-a-loyalty-program"
    }}>{`How to create a loyalty program`}</h3>
    <p>{`Creating a new Loyalty Program is usually only to be done after some communication with Entur, since
it needs to be connected to a product. Here's how it's done then:`}</p>
    <ul>
      <li parentName="ul">{`Find out which product and version you need to target`}</li>
      <li parentName="ul">{`Decide on which kind of Loyalty Program you want to create. Have a look in the `}<a parentName="li" {...{
          "href": "#time-limited-loyalty-programs"
        }}>{`section about Loyalty
Program types`}</a>{` above.`}</li>
      <li parentName="ul">{`Decide when the Loyalty program should be available. Typically, Loyalty programs have long lives.`}</li>
      <li parentName="ul">{`Decide how long a contract should be available as default. It may be several years, for instance for
customer cards.`}</li>
      <li parentName="ul">{`Decide if other organisations should be allowed to connect their customers to your contracts. This
will make it easier to adapt your loyalty program for new users, but will not drive customers to
your sales channels. Only your customers can `}<em parentName="li">{`own`}</em>{` a contract, regardless of what you decide here.`}</li>
      <li parentName="ul">{`Decide on the text you want to use to represent this loyalty program to your customers.`}</li>
    </ul>
    <p>{`Then, call the `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/benefits#/Loyalty%20programs%20admin/createLoyaltyProgram"
      }}>{`createLoyaltyProgram endpoint`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST "https://api.staging.entur.io/customers/v2/benefits/loyaltyprograms" -H"Authorization: Bearer $PARTNER_TOKEN" -H"Content-Type: application/json"
-d'
{
  "loyaltyProgramType": "TIMED",
  "productId": "ENT:DemoProduct:Demo",
  "productVersion": "ENT:ProductVersion:V1",
  "internalDescription": "Free coffee program",
  "startDate": "2020-03-13T10:26:44.118Z",
  "endDate": "2030-11-22T01:55:56+00:00",
  "usageValidityPeriod": "P365D",
  "descriptions": [
    {
      "languageCode": "ENG",
      "description": "Show your ticket, get free coffee",
      "displayName": "Demo Coffee Program"
    },
    {
      "languageCode": "NOB",
      "description": "Kaffeprogrammet til Entur gir deg gratis kaffe hos lokfører!",
      "displayName": "Demo kaffe-program"
    }
  ]
}'
`}</code></pre>
    <p>{`Provide a loyalty program code if you want users to redeem the program.
This creates a program that your company can manage in Entur Partner.`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST "https://api.staging.entur.io/customers/v2/benefits/loyaltyprograms" -H"Authorization: Bearer $PARTNER_TOKEN" -H"Content-Type: application/json"
-d'
{
  "loyaltyProgramType": "TIMED",
  "productId": "ENT:DiscountProduct:20",
  "productVersion": "ENT:ProductVersion:V1",
  "internalDescription": "20% discount coffee program",
  "startDate": "2020-03-13T10:26:44.118Z",
  "endDate": "2020-11-22T01:55:56+00:00",
  "loyaltyProgramCode": "20Coffee"
  "descriptions": [
    {
      "languageCode": "ENG",
      "description": "Get 20% off a coffee!",
      "displayName": "Demo coffee 20% discount"
    },
    {
      "languageCode": "NOB",
      "description": "Kaffeprogrammet til Entur gir deg 20% rabatt på kaffe hos lokfører!",
      "displayName": "Demo kaffe 20% rabatt"
    }
  ]
}'
`}</code></pre>
    <h3 {...{
      "id": "how-to-create-a-contract"
    }}>{`How to create a contract`}</h3>
    <p>{`Before creating a Contract, make sure you know what you want to create:`}</p>
    <ul>
      <li parentName="ul">{`Do you know which customer is going to be the owner?
If so, find the customerNumber and customerReference.`}</li>
      <li parentName="ul">{`Do you know if there is a limiting factor, such as "may only be consumed by my customers" or
"Only Jan Petersen may consume this"? If so, add the necessary policies.`}</li>
      <li parentName="ul">{`Do you have a reference you would like to show up in the sales transaction summary if this
contract is used in a sale?`}</li>
      <li parentName="ul">{`When should the customers be able to use this contract? Set consumableFrom to this time.`}</li>
      <li parentName="ul">{`Does this contract have a specific expiration date or will the default work? Set expirationDate if it is specific.`}</li>
      <li parentName="ul">{`Is the default coupons configuration ok? If not, specify another number of coupons.`}</li>
    </ul>
    <p>{`Only a few of the fields are required for creating a contract.`}</p>
    <p>{`Contracts are then created using the `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/benefits#/Contracts%20admin/createContract"
      }}>{`createContract endpoint`}</a></p>
    <p>{`Minimum:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST "https://api.staging.entur.io/customers/v2/benefits/loyaltyprograms/6/contracts" -H"Authorization: Bearer $PARTNER_TOKEN" -H"Content-Type: application/json" -d'
 {
   "consumableFrom": "2020-01-01T00:00:00+01:00",
 }'
`}</code></pre>
    <p>{`When we know more:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST "https://api.staging.entur.io/customers/v2/benefits/loyaltyprograms/6/contracts" -H"Authorization: Bearer $PARTNER_TOKEN" -H"Content-Type: application/json" -d'
 {
   "consumableFrom": "2020-01-01T00:00:00+01:00",
   "customerNumber": 12341567,
   "customerRef": "SomeCustomer",
   "externalRef": "ABCD-EFGH-1234",
   "policies": [
     {
       "key": "email",
       "value": "kari.normann@example.com"
     }
   ],
   "expirationDate": "2020-12-31T23:59:59+01:00"
 }'
`}</code></pre>
    <p>{`In the example above, the first contract is open to all, not connected to any consumers and only
accessible by uuid. The second is already connected to one consumer (as contract holder), and has
a policy that only allows a customer who supplies a specific email when attempt to consume. This
second contract can also be found via its externalRef or its consumer. The external reference is
propagated through the sales channels when used.`}</p>
    <h3 {...{
      "id": "how-to-connect-a-profile-to-a-contract"
    }}>{`How to connect a profile to a contract`}</h3>
    <p>{`A profile can be connected to a Contract in two ways:`}</p>
    <ol>
      <li parentName="ol">{`It can be the contract owner. This must be a profile from the same organisation that owns the loyalty program and contract.`}</li>
      <li parentName="ol">{`It can be a contract consumer. This can be a profile from any organisation.`}</li>
    </ol>
    <p>{`Common for both types of connections is that they must conform to the policy that has been set up for the Contract when the connection is performed.`}</p>
    <p>{`Also, a major difference here is that a contract only can have `}<strong parentName="p">{`one`}</strong>{` owner. If the owner is removed
from the contract, it will no longer be accessible.`}</p>
    <p>{`To connect to a contract, use the `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/benefits#/Contracts%20client/addContractConsumer"
      }}>{`addContractConsumer`}</a>{` endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.staging.entur.io/customers/v2/benefits/contracts/2c27bd7e-2b4d-4144-715b-fcbc8fe3807f/contract-consumer
{
  "customerOrganisationId": 20,                           #1
  "customerNumber": 1234567,                              #2
  "customerRef": "073R41D4R",                             #3
  "isContractHolder": true,                               #4
  "policyValidationProperties": {                         #5
    "email": "customer@email.example"                     #6
  }
}

Output:
{
  "customerOrganisationId": 20,
  "customerNumber": 1234567,
  "customerRef": "073R41D4R",
  "isBlocked": false,
  "isContractHolder": true,
  "createdAt": "2019-10-14T10:15:30+01:00",
  "createdBy": "clientId",
  "lastChangedAt": "2019-10-14T10:15:30+01:00",
  "lastChangedBy": "clientId"
}
`}</code></pre>
    <p>{`The input values are:`}</p>
    <ol>
      <li parentName="ol">{`The organisation of the profile you are connecting`}</li>
      <li parentName="ol">{`The customer number of the profile.`}</li>
      <li parentName="ol">{`A customer reference. This field is not required but can be provided. If present, it will be echoed in the responses.`}</li>
      <li parentName="ol">{`Whether you are trying to register a profile as the Contract Holder. Only one such is possible.`}</li>
      <li parentName="ol">{`An object containing values for validating your claim to this contract. Depending on the contract, it may have a set of values you need to match to be able to consume it. The keys must match the keys used when creating the contract. Note, the contents of these fields are stored and compared as hashed values, so they may contain gdpr data without issues.`}</li>
      <li parentName="ol">{`For instance, if the contract requires a specific email, it is sent here.`}</li>
    </ol>
    <p>{`To get the uuid for this url, you may want to look up via the `}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://api.staging.entur.io/api-docs/benefits#/Contracts%20client/getAllContractsByParameters"
      }}>{`search endpoint`}</a>{`.`}</p>
    <h3 {...{
      "id": "how-to-connect-a-profile-to-a-personnel-ticket-right"
    }}>{`How to connect a profile to a personnel ticket right`}</h3>
    <p>{`Personnel tickets are treated slightly different than other contracts. All contracts for personnel ticket
loyalty programs are created by the personnel ticket system. They simultaneously create a customer
profile and connect it as owner of the contract. All of this happens with the organisation ID 29
(the Norwegian Public Rail Administration) and therefore by default inaccessible for all partners.`}</p>
    <p>{`To mitigate this, a couple of helper-services have been created. To connect a customer to a
personnel ticket contract, there is a `}{`[separate endpoint]`}{`(`}<a parentName="p" {...{
        "href": "https://petstore.swagger.io/?url=https://developer.entur.org/static/customers/swagger-customers.json#/Contracts"
      }}>{`https://petstore.swagger.io/?url=https://developer.entur.org/static/customers/swagger-customers.json#/Contracts`}</a>{` client/claimPersonnelTicket_1).`}</p>
    <p>{`It is somewhat simpler and will make sure customers are connected correctly in both the new and old
personnel ticket regimes. It will look up the customer based on customerNumber, and connect it to
the corresponding contracts.`}</p>
    <pre><code parentName="pre" {...{}}>{`curl -X POST "https://api.staging.entur.io/customers/v2/benefits/contracts/claim-personnel-ticket" -H"Authorization: Bearer $PARTNER_TOKEN" -H"Content-Type: application/json" -d'
 {
   "externalReference": "ABCD-EF12-3456-GH45",
    "customerNumber": 1234567
 }'
`}</code></pre>
    <h3 {...{
      "id": "using-contracts-for-entitlement-products-in-the-sales-process"
    }}>{`Using contracts for entitlement products in the sales process`}</h3>
    <p>{`To use entitlement products in the sales flow, the customer must exist in Entur customers and it
must be connected to one or more entitlement products through loyalty program contracts (see the
previous two sections).`}</p>
    <p>{`Using entitlement products based on contracts is done as part of the sales flow:
`}<img alt="sales flow with entitlements" src={require("./seq_personnel_tickets_usage.png")} /></p>
    <p>{`In this graph, the actors are:`}</p>
    <ul>
      <li parentName="ul">{`Channel -> The distribution channel or backend-for-frontend for the sales agent`}</li>
      <li parentName="ul">{`getRights -> service exposed on `}<a parentName="li" {...{
          "href": "https://api.entur.io/customers/v2/benefits/entitlements/%7BcustomerNumber%7D/by-customer-number"
        }}>{`https://api.entur.io/customers/v2/benefits/entitlements/{customerNumber}/by-customer-number`}</a>{`.`}</li>
      <li parentName="ul">{`offers -> the service for fetching travel offers. See `}<a parentName="li" {...{
          "href": "https://developer.entur.org/pages-offers-docs-intro"
        }}>{`documentation`}</a></li>
      <li parentName="ul">{`order -> the service for performing a sale. See `}<a parentName="li" {...{
          "href": "https://developer.entur.org/pages-sales-intro"
        }}>{`documentation`}</a></li>
    </ul>
    <h4 {...{
      "id": "fetching-entitlements"
    }}>{`Fetching entitlements`}</h4>
    <p>{`Example: Currently logged in customer is Anders Jensen. He has customerNumber 1234567. His customer
profile is connected to his personnel ticket (personnel ticket reference "ABCD-DEFG-GH12") and his
daughters' (Josefine, reference "1234-ABCF-3456")`}</p>
    <p>{`To fetch entitlements, we call the endpoint:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.entur.io/customers/v2/benefits/entitlements/1234567/by-customer-number
[
  {  // Sølv, fritidsbillett
    "productId" : "ENT:EntitlementProduct:levelA2",
    "productVersion" : "ENT:Version:1",
    "contractUUID" : "058a9dfa-187e-4443-8660-29d8879c146a",
    "contractExternalRef" : "ABCD-DEFG-GH12",
    "contractOwnerCustomerNumber" : 4567891,
    "contractOwnerDisplayName" : "Anders",
    "contractOwnerBirthDate" : {
      "year": 1986,
      "month": 11,
      "day": 23
    },
    "contractValidFrom" : "2020-01-01T00:00:00.000",
    "contractValidTo" : "2020-01-01T00:00:00.000",
  },
  {  // sølv, tjenestereisebillett
    "productId" : "ENT:EntitlementProduct:levelA4",
    "productVersion" : "ENT:Version:1",
    "contractUUID" : "058a9dfa-187e-4443-8660-29d8879c146a",
    "contractExternalRef" : "ABCD-DEFG-GH12",
    "contractOwnerCustomerNumber" : 4567892,
    "contractOwnerDisplayName" : "Anders",
    "contractOwnerBirthDate" : {
      "year": 1986,
      "month": 11,
      "day": 23
    },
    "contractValidFrom" : "2020-01-01T00:00:00.000",
    "contractValidTo" : "2020-01-01T00:00:00.000",
  },
  {  // sølv, fritidsreisebillett
    "productId" : "ENT:EntitlementProduct:levelA2",
    "productVersion" : "ENT:Version:1",
    "contractUUID" : "47db2f5f-bf14-4c34-9856-51ba4a213cbe",
    "contractExternalRef" : "1234-ABCF-3456",
    "contractOwnerCustomerNumber" : 4585692,
    "contractOwnerDisplayName" : "Josefine",
    "contractOwnerBirthDate" : {
      "year": 2006,
      "month": 3,
      "day": 2
    },
    "contractValidFrom" : "2020-01-01T00:00:00.000",
    "contractValidTo" : "2020-01-01T00:00:00.000",
  }
]
`}</code></pre>
    <p>{`Please note in the example output:`}</p>
    <ul>
      <li parentName="ul">{`Employees hav at least two entitlements. One for private journeys and one for work travel. These
have the same externalRef.`}</li>
      <li parentName="ul">{`Family members and pensioners have just one personnel ticket entitlement - for private travel.`}</li>
      <li parentName="ul">{`The Contract Owner for the personnel ticket entitlements are owned (isContractHolder=true) by a
customer profile administrated by the personnel ticket system. This means that the
ownerCustomerNumber typically will not refer to the current customer.`}</li>
    </ul>
    <h4 {...{
      "id": "client-side-rendering"
    }}>{`Client side rendering`}</h4>
    <p>{`At this point, the sales client must present the relevant choices to the end user and offer an option
to choose which ticket rights to use, if any. For each, this is also where it is connected to the
userType - ADULT, CHILD, etc. - for each traveller. This list should be filtered based on traveldate
and the validity dates of the contracts.`}</p>
    <p>{`In our example, we could choose to present this as a list of choices:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Voksen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- +`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Barn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`- +`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Anders, Adult, private`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`+`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Anders, Adult, corporate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`+`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Josefine, Child, private`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`+`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Also, this is the place to determine if any of the travellers get senior citizens rebate.`}</p>
    <h4 {...{
      "id": "using-the-entitlements-to-get-discounted-offers"
    }}>{`Using the entitlements to get discounted offers`}</h4>
    <p>{`Once the end user has selected who is travelling, you can query offers with the entitlement products.
In the example, the end user has chosen two private travels, one for himself and one for his daughter.`}</p>
    <p><strong parentName="p"><a parentName="strong" {...{
          "href": "https://developer.entur.org/pages-offers-docs-guides-v2-search"
        }}>{`Offers v2`}</a></strong>{`
Request:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.entur.io/offers/v2/trip-pattern
...the rest of the offers query...,
"travellers": [
    {
        "id": "travelerAdultUniqueId",
        "userType": "ADULT", // OR "age": 40
        "productIds": ["ENT:EntitlementProduct:levelA3"]
    },
    {
        "id": "travelerChildUniqueId",
        "age": 11, // OR "userType": "CHILD"
        "productIds": ["ENT:EntitlementProduct:levelA2"]
    }
],
"tripPattern": {
    "legs": ...
}
... rest of offers query...
`}</code></pre>
    <p>{`Response:`}</p>
    <pre><code parentName="pre" {...{}}>{`"offers": [
{
  "id": "b39036b9-1c4e-4a22-8ddb-1c7d4bd0fa87",
  ...
  "preassignedProducts": [
    {
      ...
      "discountRight": {
        ...
        "originatingFromProductId": "ENT:EntitlementProduct:levelA3"
      }
    }
  ],
  "travellerMapping": [
    {
      "travellerIds": [
        "2d0ac23b-78aa-49df-a372-9de5abad6faa"
      ],
      "maxNumberOfTravellers": 1,
      "minNumberOfTravellers": 1,
      "userType": "ADULT"
    }
  ],
},
{
  "id": "e601e29f-598b-4a5c-9ba5-0218d90ec6f2",
  ...
  "preassignedProducts": [
    {
      ...
      "discountRight": {
        ...
        "originatingFromProductId": "ENT:EntitlementProduct:levelA2"
      }
    }
  ],
  "travellerMapping": [
    {
      "travellerIds": [
        "2d0ac23b-78aa-49df-a372-9de5abad6faa"
      ],
      "maxNumberOfTravellers": 1,
      "minNumberOfTravellers": 1,
      "userType": "CHILD"
    }
  ],
  ...
},
`}</code></pre>
    <ExpandablePanel title="Offers v1" mdxType="ExpandablePanel">
    <p>Request:</p>
    <p>POST https://api.entur.io/sales/v1/offers/search</p>
    <pre>{`...the rest of the offers query...,
        "travelers": [
            {
                "id": "traveler1",
                "userType": "ADULT"
                "products": [
                    {
                        "id" : "ENT:EntitlementProduct:levelA2",
                        "version" : "ENT:Version:1",
                     }
                    ]
                },
            {
                "id": "traveler2",
                "userType": "CHILD"
                "products": [
                    {
                        "id" : "ENT:EntitlementProduct:levelA2",
                        "version" : "ENT:Version:1",
                    }
                ]
            }
        ]`}
    </pre>
    <p>Response:</p>
    <pre>{`
        "offers": [
            {
                "id": "8b2cfd32-836a-40f8-b244-d7bef739144d",
                ...
                "travellerMapping": [
                    {
                        "travelerIds": [ "traveler1" ],
                    "maxNumberOfTravelers": 1,
                    "minNumberOfTravelers": 1,
                    "userProfileId": "NSB:UserProfile:Adult",
                    "userProfileIds": []
                    }
            },
            {
                "id": "8b2cfd32-836a-40f8-b244-d7bef739144e",
                ...
                "travellerMapping": [
                    {
                        "travelerIds": [ "traveler2" ],
                "maxNumberOfTravelers": 1,
                "minNumberOfTravelers": 1,
                "userProfileId": "NSB:UserProfile:Child",
                "userProfileIds": []
            }
          ]
        }
      ]`}
    </pre>
    </ExpandablePanel>
    <p>{`Since the offers service has no concept about a `}<em parentName="p">{`customer`}</em>{` we need to pass the entitlements for the
correct travellers to get the correct prices. Internally, the entitlement product we send in has a
connection to a SalesDiscountRight which is applied to the prices before returning from offers.
When passed an entitlement product, offers will locate valid SalesDiscountRights and apply them.
Which SalesDiscountRights were applied can be read directly from the returned offer in the section
called FareProductConfiguration. Any calculated discounts can be found at jpath
offers[].salesPackageConfig.fareProducts[].discountRight.parameters.entitlementGiven[]`}</p>
    <p>{`We recommend the savings are shown to the end user:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": "right"
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Oslo S - Bergen, Vy`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": "right"
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Normal price`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`1579`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Personnel ticket discount (*)`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`-1579`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Seat 45B`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`59`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Total`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`59`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Here, the (*) may show some text about this kind of discount being reported to the Tax Authorities.`}</p>
    <h4 {...{
      "id": "accepting-the-offer-and-payment"
    }}>{`Accepting the offer and payment`}</h4>
    <p>{`Once the offer above is accepted, the procedure for creating the order requires some small changes
when using entitlements.`}</p>
    <p>{`To begin, we need to know if the current logged in user is allowed to use those entitlements. To do
this, we need to have that person as creator of the Order:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.entur.io/sales/v1/orders
{
  ... other order-values ...,
  "contactInfo": {
    "createdBy": {
      "id": 1234567
    }
  }
}
`}</code></pre>
    <p>{`Then, the offer is converted to an order. Here, we need to include who is travelling (so names on
personnel tickets can be correct). Also, which contracts and entitlement product were actually
chosen for the different travellers. This is done when calling reserve-offers with an appropriate
offerConfiguration:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.entur.io/sales/v1/reserve-offer
"offerConfigurations": [
  {
    "offerId": "8b2cfd32-836a-40f8-b244-d7bef739144d",
    "customers": [
      {
        "customerId": "4567891",
        "entitlements": [
          {
            "contractId": "058a9dfa-187e-4443-8660-29d8879c146a",
            "entitlementProductRef": {
              "id": "ENT:EntitlementProduct:levelA2"
            },
            "externalEntitlementRef": {
              "id": "ABCD-DEFG-GH12"
            }
          }
        ]
      }
    ]
  },{
    "offerId": "8b2cfd32-836a-40f8-b244-d7bef739144e",
    "customers": [
      {
        "customerId": "4585692",
        "entitlements": [
          {
            "contractId": "47db2f5f-bf14-4c34-9856-51ba4a213cbe",
            "entitlementProductRef": {
              id": "ENT:EntitlementProduct:levelA1"
            },
            "externalEntitlementRef": {
              "id": "1234-ABCF-3456
            }
          }
        ]
      }
    ]
  }
]
`}</code></pre>
    <p>{`Note about the example: Each traveller gets their own offerConfiguration. For each element we
specify who the traveller is through the customer object (with customer number from the entitlement),
and which contract and entitlement product were used.`}</p>
    <p>{`This is then used internally to check if the creator is allowed to use the entitlements they are
presenting. Note that these credentials and the activation time are checked a second time when
activating the ticket.`}</p>
    <p>{`NOTE: An entitlement must be valid for the customer on the date of travel when creating the order
and, for period tickets, both when attempting to activate the ticket and on the expiry date.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      